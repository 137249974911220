import React, { useCallback, useEffect, useMemo } from 'react'
import { Modal, ModalOverlay, ModalContent, useToast } from '@chakra-ui/react'
import modalMachine from './modalMachine'
import { useMachine } from '@xstate/react'

import { formatToTwoSignificantDigitsAfterDecimal } from '../../../../utils'

import Header from './components/Header'
import Body from './components/Body'
import Footer from './components/Footer'
import useSolana from '../../hooks/useSolana'
import { Pack } from './types'

type BuyingModalProps = {
  partnerToken: string
  packId?: string
  packDetails?: Pack
  twitterMessageUrl?: string
  twitterMessageText?: string
  isOpen: boolean
  onClose: () => void
}

const BuyingModal: React.FC<BuyingModalProps> = ({ partnerToken, packId, packDetails, twitterMessageUrl, twitterMessageText, isOpen, onClose }) => {
  const [state, send] = useMachine(modalMachine, { input: { partnerToken, packId, packDetails, twitterMessageUrl, twitterMessageText } })
  const { connected, connect, walletAddress, signAndSendTransactions, checkTransactions } =
    useSolana()


  // Debugging
  useEffect(() => {
    if (state.matches('errorFetchTxs')) {
      console.log(state.context.error)
    }
  }, [state])

  /* 
  Toasters are used to show messages and errors to the user.
  */
  const toast = useToast()

  // Show toast on message
  useEffect(() => {
    if (state.context.message) {
      toast({
        title: 'Message',
        description: state.context.message,
        status: 'info',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [state.context.message, toast])

  // Show toast on error
  useEffect(() => {
    if (state.context.error) {
      const error = state.context.error as Error
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [state.context.error, toast])

  /* 
  Solana interaction.
  */
  // Connect wallet
  useEffect(() => {
    if (state.matches('connect')) {
      if (connected) {
        send({ type: 'CONNECT', walletAddress })
      } else {
        connect()
      }
    }
  }, [connect, connected, state.value])

  // Sign and send transactions
  useEffect(() => {
    if (state.matches('signTxs')) {
      signAndSendTransactions(state.context.txs)
        .then((results) => {
          send({ type: 'SIGN', txs: results })
        })
        .catch((error) => {
          send({ type: 'REJECT', error })
        })
    }
  }, [signAndSendTransactions, state.value])

  // Check transactions
  useEffect(() => {
    const checkTxs = async () => {
      if (state.matches('checkTxs')) {
        checkTransactions(state.context.txs, true)
          .then((updatedTransactions) => {
            console.log('updatedTransactions', updatedTransactions)
            send({ type: 'UPDATE', txs: updatedTransactions })
          })
          .catch((error) => {
            send({ type: 'ERROR', error })
          })
      }
    }

    let intervalId: NodeJS.Timer
    if (state.matches('checkTxs')) {
      intervalId = setInterval(checkTxs, 2000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [checkTransactions, state.value])

  /*
  Values shared between the header, body and footer components.
  */
  const uiPrice = useMemo(() => {
    return state.context.packDetails?.price
      ?.map((price) => {
        return `${formatToTwoSignificantDigitsAfterDecimal(
          price.amount / 10 ** price.token.decimals
        )} ${price.token.symbol}`
      })
      .join(' + ')
  }, [state.context.packDetails?.price])

  const canBuy = useMemo(() => {
    if (!state.context.balances) return false
    const everyBalanceOk = state.context.packDetails?.price?.every((price) => {
      const tokenBalance = state.context.balances.find(
        (token) => token.token.mint === price.token.mint
      )
      return tokenBalance && tokenBalance.amount >= price.amount
    })

    return everyBalanceOk
  }, [state.context.packDetails?.price, state.context.balances])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="5xl"
      isCentered
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      trapFocus={false}
    >
      <ModalOverlay />
      <ModalContent className="buying-modal-content">
        <Header state={state} uiPrice={uiPrice} />
        <Body state={state} uiPrice={uiPrice} canBuy={canBuy} send={send}/>
        <Footer state={state} onClose={onClose} send={send} canBuy={canBuy} />
      </ModalContent>
    </Modal>
  )
}

export default BuyingModal

import React, { useEffect } from 'react';
import PacksWidget from '../components/PacksWidget/PacksWidget'


export default function StarAtlasPacksWidget() {
  useEffect(() => {
    import('../components/PacksWidget/css/starAtlas.scss');
  }, []);
  return (<PacksWidget partnerToken={process.env.REACT_APP_STAR_ATLAS_TOKEN!} loadDinamicCSS={false} />);
}
